import { For, Match, Show, Switch, createMemo, splitProps } from "solid-js";
import type { Components } from "./_model";
import { global } from ":global";
export function Table<T extends any[]>(props: IntrinsicElement<"section"> & Components.ATable<T>) {
  const [local, others] = splitProps(props, ["rows", "columns", "fallback", "classes", "triggers", "events"]);
  const $breakpoint = global.store.hooks.dom.useBreakpoints();
  const $columns = createMemo(() => (local.columns ? Object.entries(local.columns) : null));
  const $helpers: Components.ATableColumnSettingsKeyHelpers = createMemo(() => {
    return {
      isDesktop: $breakpoint().misc.md.widthIsGreaterOrEquals,
    };
  });

  return (
    <section {...others} class={`flex flex-col w-full h-full !min-h-fit overflow-y-auto ${others.class ?? ""}`}>
      <Switch>
        <Match when={$breakpoint()?.misc?.md?.widthIsLess}>
          <Show when={local.rows?.length > 0 && $columns()?.length > 0} fallback={local.fallback}>
            <div
              class={`flex flex-col w-full min-h-full overflow-y-auto ${local.classes?.phone?.cardsContainer ?? ""}`}
            >
              <For each={local.rows}>
                {(r, rowIndex) => {
                  const active = createMemo(() => local.events?.isActiveRow?.(r));
                  const hidden = createMemo(() => local.events?.isHidden?.(r));
                  return (
                    // each row div
                    <Show when={!hidden()}>
                      <div
                        class={`flex flex-col w-full items-center overflow-hidden h-fit  
                        ${local.classes?.phone?.singleCardContainer ?? ""}
                      ${active() ? local.classes?.phone?.singleCardContainerActive : ""}
                      `}
                      >
                        <For each={$columns()}>
                          {([c, item], columnIndex) => {
                            const $value = createMemo(() => {
                              if (typeof item.key === "function") {
                                return item.key(r, $helpers, rowIndex);
                              }
                              if (item.key === "INDEX") {
                                return rowIndex();
                              }
                              return r[item.key];
                            });
                            return (
                              <div
                                onclick={() => !item.disableOnRowClick && local.triggers?.onRowClicked?.(r, rowIndex)}
                                style={{
                                  height: item.phone?.height,
                                  "max-height": item.phone?.height,
                                  "min-height": item.phone?.height,
                                }}
                                class={`flex flex-row items-center justify-between  
                                ${local.classes?.phone?.allCardsRows ?? ""}  
                                ${active() ? local.classes?.phone?.allCardsRowsActive : ""}  
                                ${item.phone?.class ?? ""}`}
                              >
                                <p
                                  style={{
                                    width: item?.phone?.headerWidth,
                                    "max-width": item.phone?.headerWidth,
                                    "min-width": item.phone?.headerWidth,
                                  }}
                                  class={`flex flex-row h-fit items-center text-0.7rem ${
                                    local.classes?.phone?.singleCardHeader ?? ""
                                  } ${item.phone?.cardHeaderClass ?? ""}`}
                                >
                                  {c}
                                </p>
                                <p
                                  style={{
                                    width: item.phone?.valueWidth,
                                    "max-width": item.phone?.valueWidth,
                                    "min-width": item.phone?.valueWidth,
                                  }}
                                  class={`flex flex-row h-fit items-center ${
                                    local.classes?.phone?.singleCardValue ?? ""
                                  } ${item.phone?.cardValueClass ?? ""}`}
                                >
                                  {$value()}
                                </p>
                              </div>
                            );
                          }}
                        </For>
                      </div>
                    </Show>
                  );
                }}
              </For>
            </div>
          </Show>
        </Match>
        <Match when={$breakpoint()?.misc?.md?.widthIsGreaterOrEquals}>
          <div class={`flex flex-row w-full items-center ${local.classes?.desktop?.headersContainer ?? ""}`}>
            <For each={$columns()}>
              {([c, item], index) => {
                return (
                  <p
                    style={{
                      width: item.desktop?.width,
                      "max-width": item.desktop?.width,
                      "min-width": item.desktop?.width,
                    }}
                    class={`flex flex-row items-center flex-1
                    overflow-x-auto
                    ${local.classes?.desktop?.allSlots ?? ""}  
                    ${local.classes?.desktop?.singleHeader ?? ""}
                    ${item.desktop?.class ?? ""} 
                    ${item.desktop?.headerClass ?? ""}`}
                  >
                    {item.desktop?.icon}
                    {c}
                  </p>
                );
              }}
            </For>
          </div>
          <Show when={local.rows?.length > 0 && $columns()?.length > 0} fallback={local.fallback}>
            <div class={`flex flex-col w-full overflow-y-auto ${local.classes?.desktop?.rowsContainer ?? ""}`}>
              <For each={local.rows}>
                {(r, rowIndex) => {
                  const active = createMemo(() => local.events?.isActiveRow?.(r));
                  const hidden = createMemo(() => local.events?.isHidden?.(r));
                  return (
                    // each row div
                    <Show when={!hidden()}>
                      <div
                        class={`flex flex-row w-full items-center overflow-hidden min-h-100px h-100px 
                        ${local.classes?.desktop?.singleRowContainer ?? ""}
                        ${active() ? local.classes?.desktop?.singleRowContainerActive : ""}
                      `}
                      >
                        <For each={$columns()}>
                          {([c, item], columnIndex) => {
                            const $value = createMemo(() => {
                              if (typeof item.key === "function") {
                                return item.key(r, $helpers, rowIndex);
                              }
                              if (item.key === "INDEX") {
                                return rowIndex();
                              }
                              return r[item.key];
                            });
                            return (
                              <div
                                onclick={() => !item.disableOnRowClick && local.triggers?.onRowClicked?.(r, rowIndex)}
                                style={{
                                  width: item.desktop?.width,
                                  "max-width": item.desktop?.width,
                                  "min-width": item.desktop?.width,
                                }}
                                class={`flex flex-row 
                              overflow-x-auto
                              ${local.classes?.desktop?.allSlots ?? ""}  
                              ${local.classes?.desktop?.singleRow ?? ""} 
                              ${item.desktop?.class ?? ""} 
                              ${item.desktop?.rowClass ?? ""} `}
                              >
                                {$value()}
                              </div>
                            );
                          }}
                        </For>
                      </div>
                    </Show>
                  );
                }}
              </For>
            </div>
          </Show>
        </Match>
      </Switch>
    </section>
  );
}
